import { motion } from 'framer-motion'; // Importing framer-motion for transitions
import './styles/whyus.css';
import Whyus from './images/Time management.svg';

export default function WhyUs() {
    return (
        <div className="whyus_main">
            <motion.div
                id="whyUsHead"
                initial={{ opacity: 0, y: 30 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.6 }}
            >
                Why choose us
            </motion.div>

            <div id="whyUsMainContainer">
                <motion.div
                    id="whyUsHeadSub"
                    initial={{ opacity: 0, y: 30 }}
                    whileInView={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.6 }}
                >
                    Driven by a passion for innovation and a commitment to exceptional customer experiences, we deliver forward-thinking solutions that empower businesses and individuals to thrive.
                </motion.div>

                <div className="why_us_main">
                    <div className="why_us_point_container">
                        {/* Expertise Section */}
                        <motion.div
                            className='why_us_point'
                            initial={{ opacity: 0, y: 30 }}
                            whileInView={{ opacity: 1, y: 0 }}
                            transition={{ duration: 0.6 }}
                        >
                            <div className="why_us_point_heading">Expertise</div>
                            <div>
                                Our team comprises seasoned professionals with a wealth of experience in software development across diverse industries.
                            </div>
                        </motion.div>

                        {/* Innovation Section */}
                        <motion.div
                            className='why_us_point'
                            initial={{ opacity: 0, y: 30 }}
                            whileInView={{ opacity: 1, y: 0 }}
                            transition={{ duration: 0.6 }}
                        >
                            <div className="why_us_point_heading">Innovation</div>
                            <div>
                                We stay ahead of the curve by embracing the latest technologies and methodologies to deliver solutions that exceed expectations.
                            </div>
                        </motion.div>

                        {/* Customization Section */}
                        <motion.div
                            className='why_us_point'
                            initial={{ opacity: 0, y: 30 }}
                            whileInView={{ opacity: 1, y: 0 }}
                            transition={{ duration: 0.6 }}
                        >
                            <div className="why_us_point_heading">Customization</div>
                            <div>
                                Every project is unique, and we tailor our approach to meet your specific goals, ensuring a solution that aligns perfectly with your vision.
                            </div>
                        </motion.div>

                        {/* Collaboration Section */}
                        <motion.div
                            className='why_us_point'
                            initial={{ opacity: 0, y: 30 }}
                            whileInView={{ opacity: 1, y: 0 }}
                            transition={{ duration: 0.6 }}
                        >
                            <div className="why_us_point_heading">Collaboration</div>
                            <div>
                                We believe in transparent communication and close collaboration with our clients every step of the way, ensuring that your input shapes the final product.
                            </div>
                        </motion.div>
                    </div>
                </div>
            </div>
        </div>
    );
}
