import '../resources/styles/global.css'
import '../resources/styles/index.css'

import Nav from '../elements/Nav/Nav'
import Footer from '../elements/Footer/footer'

import '../elements/services/styles/pos.css'
import '../elements/services/styles/ml.css'

import TextField from '@mui/material/TextField';

export default function Home(){
    return(
        <div className="">
            <Nav/>
            <div className="contactsMain">
                
                <div className="contactsList">
                    <div>
                        <div className='contactType'>
                            <div></div>
                            <div className="contactTypeTitle">Chat to us</div>
                            <div>Our team is always happy to help.</div>
                            <div className="contact_">sublimetechnologiestanzania@gmail.com</div>
                        </div>

                        <div className='contactType'>
                            <div></div>
                            <div className="contactTypeTitle">Visit us</div>
                            <div>Come say hello at our office HQ</div>
                            <div className="contact_">Pangani street Block 3 plot J: JMHQ+6G Arusha</div>
                        </div>

                        <div className='contactType'>
                            <div></div>
                            <div className="contactTypeTitle">Call us</div>
                            <div>Mon-Fri from 8am to 6pm</div>
                            <div className="contact_">+ (255) 623 082 331</div>
                        </div>
                    </div>
                    <div>
                        <div></div>
                    </div>
                </div>

                <div className="contactsForm">
                    <div className="contactHeadForm">
                        <div className='contactHeadTitle'>
                            Questions or Concerns? Our Dedicated Team is Here to Help You Every Step of the Way
                        </div>
                        <div>
                            What's on Your Mind? Share Your Questions, Goals, or Challenges with Our Team
                        </div>
                    </div>
                    <div className="contactInputContainer">
                        <TextField className="contactInput" label="Your name" variant="standard" />
                    </div>
                    <div className="contactInputContainer">
                        <TextField className="contactInput" label="Your company" variant="standard" />
                    </div>
                    <div className="contactInputContainer">
                        <TextField className="contactInput" label="Tell us abit about the project..." variant="standard" />
                    </div>
                    <div className="getStarted">
                        <div className='btn'>
                            Let's get started
                        </div>
                    </div>
                </div>


            </div>
            <div>
                
            </div>
            <Footer/>
        </div>
    )
}