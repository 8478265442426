export default function whyus(){
    return(
        <div className="project underlined-project">

                <div className="projectKeysContainer">
                    <div className="projectKeysInlineHead">
                        <div className="projectKey">      
                            <div className="projectKeyTitle">
                                Strong online presence and brand awareness
                            </div>
                            <div>
                                Create a strong online presence, improve brand awareness, and establish your brand identity.
                            </div>
                        </div>
                        <div className="InlineHead">
                            <div className='projectHead'>
                                <span className="projectNumber">06</span>
                                <span className='projectHeadTitle2'>Websites, Web apps, Web systems and API's</span>                                
                            </div>

                        </div>
                        <div className="projectKey">
                            <div className="projectKeyTitle">
                                Improved customer engagement and conversion rates
                            </div>
                            <div>
                                Improve customer engagement, increase conversion rates, and drive business growth.
                            </div>
                        </div>
                        <div className="projectKey">
                            <div className="projectKeyTitle">
                                Enhanced user experience and satisfaction
                            </div>
                            <div>
                                Create a user-friendly and intuitive website that improves user experience and satisfaction.
                            </div>
                        </div>
                        <div className="projectKey">
                            <div className="projectKeyTitle">
                                Better search engine rankings and visibility
                            </div>
                            <div>
                                Optimize your website for search engines, improve rankings, and increase visibility.
                            </div>
                        </div>
                    </div>
                </div>

                <div className="projectIntro">
                    
                    Establish a strong online presence with our custom website solutions, crafted to meet your unique business needs.
                </div>
     

          
           

        </div>
    )
}