import './styles/process.css'

export default function Stage(props){

    function selectStage(event){
        props.select(event.target.dataset.stage)
    }

    return(
        props.active !== props.title ?

        <div className="processStage" data-stage={props.title}  onClick={selectStage}>
            <div className="stageTopNav">
                <div className="stageIcon">
                    <img src={props.icon} alt="stage illustration"/>
                </div>
                <div className="stageTitle">{props.title}</div> 
            </div>
        </div>: 
        <div className="processStageActive">
            <div className="stageTopNav">
                <div className="stageIconActive">
                    <img src={props.icon} alt="stage illustration"/>
                </div>
                <div className="stageTitle">{props.title}</div> 
            </div>

            <div>{props.description}</div>
        </div>
        
    )
}