import React from 'react';
import logo from '../resources/images/logo.svg';
import './styles/footer.css';

const Footer = () => {
    const currentYear = new Date().getFullYear();

    return (
        <footer className="footer">
            {/* Logo and Branding */}
            <div className="footerHeader">
                <img src={logo} alt="Sublime Technologies logo" className="footerLogo" />
                <div className="footerBrand">
                    <h1 className="footerCompanyName">Sublime Technologies</h1>
                    <p className="footerTagline">Crafting Creative Solutions</p>
                </div>
            </div>

            {/* Footer Sections */}
            <div className="footerContent">
                {/* Contact Information */}
                <div className="footerSection">
                    <h2 className="footerSectionTitle">Contact Us</h2>
                    <ul className="footerList">
                        <li>
                            <strong>Office:</strong> Block 3 Plot J Pangani Street, Arusha, Tanzania
                        </li>
                        <li>
                            <strong>Email:</strong> sublimetechnologiestanzania@gmail.com
                        </li>
                        <li>
                            <strong>Phone:</strong> +255 623 082 331
                        </li>
                    </ul>
                </div>

                {/* Newsletter Subscription */}
                <div className="footerSection">
                    <h2 className="footerSectionTitle">Stay Connected</h2>
                    <p>Subscribe to our newsletter to receive the latest updates:</p>
                    <form className="footerForm">
                        <input
                            type="email"
                            placeholder="Enter your email"
                            className="footerInput"
                            aria-label="Email address"
                            required
                        />
                        <button type="submit" className="footerButton">
                            Subscribe
                        </button>
                    </form>
                </div>

                {/* Social Media Links */}
                <div className="footerSection">
                    <h2 className="footerSectionTitle">Follow Us</h2>
                    <div className="socialIcons">
                        <a href="#" aria-label="Facebook" className="socialIcon facebook"></a>
                        <a href="#" aria-label="Twitter" className="socialIcon twitter"></a>
                        <a href="#" aria-label="LinkedIn" className="socialIcon linkedin"></a>
                        <a href="#" aria-label="Instagram" className="socialIcon instagram"></a>
                    </div>
                </div>
            </div>

            {/* Footer Bottom */}
            <div className="footerBottom">
                <p>
                    &copy; {currentYear} Sublime Technologies. All Rights Reserved.
                </p>
            </div>
        </footer>
    );
};

export default Footer;
