export default function whyus(){
    return(
        <div className="project">
            
            

                <div className="projectKeysContainer">
                    <div className="projectKeysInlineHead">
                        <div className="projectKey">      
                            <div className="projectKeyTitle">
                                Improved efficiency and productivity
                            </div>
                            <div>
                                Automate manual tasks, optimize processes, and improve productivity with custom software solutions.
                            </div>
                        </div>

                        <div className="InlineHead">

                            <div className='projectHead'>
                                <span className="projectNumber">07</span>
                                <span className='projectHeadTitle2'>Custom tailor made solutions</span>
                                
                            </div>

                        </div>
                        <div className="projectKey">
                            <div className="projectKeyTitle">
                                Enhanced competitiveness with tailored software solutions
                            </div>
                            <div>
                                Use custom software solutions to stay ahead of the competition, improve efficiency, and drive business growth.
                            </div>
                        </div>
                        <div className="projectKey">
                            <div className="projectKeyTitle">
                                Increased customer satisfaction and loyalty
                            </div>
                            <div>
                                Provide personalized service, improve customer engagement, and increase customer satisfaction with custom software solutions.
                            </div>
                        </div>
                        <div className="projectKey">
                            <div className="projectKeyTitle">
                                Better decision-making with data-driven insights
                            </div>
                            <div>
                                Get real-time insights into business operations, track KPIs, and make informed decisions with custom software solutions.
                            </div>
                        </div>
                    </div>
                </div>

                <div className="projectIntro">
                    
                    Our team of experts will work closely with you to design and develop bespoke software solutions that address your specific challenges and goals.
                </div>



        </div>
    )
}