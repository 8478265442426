import './styles/openai.css';
import image from './images/diego.jpg';
import { motion } from 'framer-motion'; // Import Framer Motion
import { useInView } from 'react-intersection-observer'; // Import useInView from react-intersection-observer

export default function Services() {
  // Use the IntersectionObserver hook from the `react-intersection-observer` package
  const { ref, inView } = useInView({
    triggerOnce: false, // Allow animation to trigger each time the component enters view
    threshold: 0.2, // Trigger when 20% of the component is in view
  });

  return (
    <div className="servicesContainer">
      {/* Header Section */}
      <motion.header
        className="header"
        ref={ref} // Attach the ref to detect visibility
        initial={{ opacity: 0, y: -50 }} // Start hidden and above
        animate={{ opacity: inView ? 1 : 0, y: inView ? 0 : -50 }} // Animate to visible and slide up when in view
        transition={{ duration: 1 }}
      >
        <h1
          className="title"
          
        >
          Innovative Solutions
        </h1>
        <p
          className="subtitle"
          
        >
          Delivering expertise in web development, AI, and 3D modeling to shape a smarter future.
        </p>
      </motion.header>

      {/* Main Content Section */}
      <motion.main
        className="content"
        initial={{ opacity: 0, y: 50 }} // Start hidden and below
        animate={{ opacity: inView ? 1 : 0, y: inView ? 0 : 50 }} // Animate to visible and slide up
        transition={{ duration: 1, delay: 0.6 }}
      >
        <motion.img
          src={image}
          alt="Innovation concept"
          className="mainImage"
          initial={{ opacity: 0, x: -100 }} // Start off to the left
          animate={{ opacity: inView ? 1 : 0, x: inView ? 0 : -100 }} // Animate to visible and slide in
          transition={{ duration: 1 }}
        />
        <motion.p
          className="description"
          initial={{ opacity: 0, x: -100 }} // Start off to the left
          animate={{ opacity: inView ? 1 : 0, x: inView ? 0 : -100 }} // Animate to visible and slide in
          transition={{ duration: 1, delay: 0.3 }}
        >
          We specialize in cutting-edge technologies that empower businesses to innovate and grow.
          Discover how we can bring your ideas to life with tailored solutions.
        </motion.p>
        <motion.button
          className="ctaButton"
          initial={{ opacity: 0 }} // Start with hidden
          animate={{ opacity: inView ? 1 : 0 }} // Fade in when in view
          transition={{ duration: 1, delay: 0.7 }}
          onClick={() => alert('Get Started clicked!')}
        >
          Get Started
        </motion.button>
      </motion.main>
    </div>
  );
}
