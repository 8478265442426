import './styles/services.css';
import Globe from './images/planet-earth.png';
import Application from './images/categories.png';
import Model from './images/neural-network.png';
import Object from './images/cube.png';
import Animation from './images/animation.png';
import Shapes from './images/shapes(1).png';
import { motion } from 'framer-motion'; // Import Framer Motion
import { useInView } from 'react-intersection-observer'; // Import useInView from react-intersection-observer

export default function Services() {
  return (
    <div className="services_main">
      <div id="servicesTitleBar">
        <div className="servicesTitle">
          Unleash Your Potential with Our Comprehensive Services
        </div>
        <div className="services_expounded">
          <div className="servicesTitleBarContent">
            Discover Our Range of Tailored Software Solutions, Crafted to Empower Your
            Business and Drive Success in the Digital Era.
          </div>
        </div>
      </div>

      <div className="services_list">
        <ServiceItem
          title="Website Development"
          description="Beautifully crafted websites that leave a lasting impression on your visitors."
          image={Globe}
        />
        <ServiceItem
          title="Mobile App Development"
          description="Engaging mobile applications for iOS and Android platforms."
          image={Application}
        />
        <ServiceItem
          title="Machine Learning"
          description="Advanced machine learning models that drive insights, predictions, and automation."
          image={Model}
        />
        <ServiceItem
          title="3D Modeling"
          description="Bring your concepts to life with precision and creativity through 3D modeling."
          image={Object}
        />
        <ServiceItem
          title="3D Animation"
          description="3D animations that communicate your message effectively and leave a lasting impact."
          image={Animation}
        />
        <ServiceItem
          title="Computer Software Development"
          description="We develop reliable and scalable solutions for all platforms."
          image={Shapes}
        />
      </div>
    </div>
  );
}

const ServiceItem = ({ title, description, image }) => {
  const { ref, inView } = useInView({
    triggerOnce: false, // Allow animation to trigger each time the component enters view
    threshold: 0.2, // Trigger when 20% of the component is in view
  });

  return (
    <motion.div
      className="serviceContainer"
      ref={ref}
      initial={{ opacity: 0, y: 50 }} // Start with opacity 0 and slightly moved down
      animate={{ opacity: inView ? 1 : 0, y: inView ? 0 : 50 }} // Animate opacity and slide up when in view
      transition={{ duration: 1 }}
    >
      <div className="serviceIllustrationContainer">
        <motion.img
          src={image}
          alt="service illustration"
          initial={{ opacity: 0, x: -100 }} // Start with opacity 0 and slightly to the left
          animate={{ opacity: inView ? 1 : 0, x: inView ? 0 : -100 }} // Animate opacity and slide in
          transition={{ duration: 1 }}
        />
      </div>
      <div className="serviceTitle">{title}</div>
      <div className="serviceMain">{description}</div>
    </motion.div>
  );
};
