export default function whyus(){
    return(
        <div className="project underlined-project">
        
              

                <div className="projectKeysContainer">
                    <div className="projectKeysInlineHead">
                        <div className="projectKey">      
                            <div className="projectKeyTitle">
                                Engaging and memorable brand storytelling
                            </div>
                            <div>
                                Create engaging and memorable brand stories that capture the essence of your brand.
                            </div>
                        </div>
                        <div className="InlineHead">
                            <div className='projectHead'>
                                <span className="projectNumber">05</span>
                                <span className='projectHeadTitle2'>3D Modeling and animation</span>
                            </div>
                        </div>
                        <div className="projectKey">
                            <div className="projectKeyTitle">
                                Increased customer engagement and loyalty
                            </div>
                            <div>
                                Use animation to communicate complex ideas, improve customer engagement, and increase loyalty.
                            </div>
                        </div>
                        <div className="projectKey">
                            <div className="projectKeyTitle">
                                Improved brand awareness and recognition
                            </div>
                            <div>
                                Use animation to create brand awareness, improve recognition, and establish your brand identity.
                            </div>
                        </div>

                        <div className="projectKey">
                            <div className="projectKeyTitle">
                                Enhanced marketing and advertising campaigns
                            </div>
                            <div>
                                Use animation to create engaging and memorable marketing and advertising campaigns.
                            </div>
                        </div>
                    </div>
                </div>

                <div className="projectIntro">
                    
                    Bring your brand to life with our captivating animation services, perfect for creating engaging stories and experiences.

                </div>

        </div>
    )
}