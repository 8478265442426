import { motion } from 'framer-motion'; // Importing framer-motion for transitions
import './styles/clients.css';
import neng from './images/neng.png';
import dorcas from './images/logo.png';
import nsk from './images/nsk.png';
import sunkist from './images/sunkist.png';
import inmajo from './images/inmajo.png';
import ushindi from './images/ushindi.png';
import freetown from './images/freetown.svg';

export default function Services() {
    return (
        <div className="clients">
            <div className="clientHead">
                <div className="clientHeadTitle">
                    Trusted by Industry Leaders
                </div>
                <div className="headDetails">
                    We have garnered the trust of industry leaders through our commitment to delivering innovative and reliable software solutions that drive business success.
                </div>
            </div>
            <div className="clientsList">
                {/* Client 1 */}
                <motion.div
                    className="client"
                    initial={{ opacity: 0, y: 30 }}
                    whileInView={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.6 }}
                >
                    <div>
                        <img src={neng} alt="neng" />
                    </div>
                    <div>Neng lang et safaries</div>
                </motion.div>

                {/* Client 2 */}
                <motion.div
                    className="client"
                    initial={{ opacity: 0, y: 30 }}
                    whileInView={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.6 }}
                >
                    <div>
                        <img src={dorcas} alt="dorcas" />
                    </div>
                    <div>Dorcas</div>
                </motion.div>

                {/* Client 3 */}
                <motion.div
                    className="client"
                    initial={{ opacity: 0, y: 30 }}
                    whileInView={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.6 }}
                >
                    <div>
                        <img src={nsk} alt="nsk hospital" />
                    </div>
                    <div>nsk hospital</div>
                </motion.div>

                {/* Client 4 */}
                <motion.div
                    className="client"
                    initial={{ opacity: 0, y: 30 }}
                    whileInView={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.6 }}
                >
                    <div>
                        <img src={ushindi} alt="ushindi micro credit" />
                    </div>
                    <div>ushindi micro credit</div>
                </motion.div>

                {/* Client 5 */}
                <motion.div
                    className="client"
                    initial={{ opacity: 0, y: 30 }}
                    whileInView={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.6 }}
                >
                    <div>
                        <img src={inmajo} alt="inmajo micro finance" />
                    </div>
                    <div>inmajo micro finance</div>
                </motion.div>

                {/* Client 6 */}
                <motion.div
                    className="client freetown"
                    initial={{ opacity: 0, y: 30 }}
                    whileInView={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.6 }}
                >
                    <div>
                        <img src={freetown} alt="free town restaurant" />
                    </div>
                    <div>free town restaurant</div>
                </motion.div>

                {/* Client 7 */}
                <motion.div
                    className="client"
                    initial={{ opacity: 0, y: 30 }}
                    whileInView={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.6 }}
                >
                    <div>
                        <img src={sunkist} alt="sunkist" />
                    </div>
                    <div>sunkist</div>
                </motion.div>
            </div>
        </div>
    );
}
