export default function whyus(){
    return(
        <div className="project underlined-project">
  
                


            <div className="projectKeysContainer">
                <div className="projectKeysInlineHead">
                    <div className="projectKey">
                        <div className="projectKeyTitle">
                            Faster transaction processing and reduced wait times
                        </div>
                        <div>Process transactions quickly and efficiently to reduce wait times and improve customer satisfaction.</div>
                    </div>

                    <div className="InlineHead">
                        <div className='projectHead'>
                            <span className="projectNumber">02</span> 
                            <span className='projectHeadTitle2'>Point of sale system</span> 
                            
                        </div>
                    
                        
                    </div>
                    <div className="projectKey">
                        <div className="projectKeyTitle">
                            Improved accounting
                        </div>
                        <div>Simplify your accounting processes, reduce errors, and improve financial management with features such as automated sales tracking and reporting and real-time inventory valuation</div>
                    </div>
                    <div className="projectKey">
                        <div className="projectKeyTitle">
                            Improved inventory management and reduced stockouts
                        </div>
                        <div>Keep track of inventory levels, optimize ordering, and reduce stockouts to improve customer satisfaction.</div>
                    </div>
                    
                    <div className="projectKey">
                        <div className="projectKeyTitle">
                            Better decision-making with real-time data
                        </div>
                        <div>Get real-time insights into sales, inventory, and customer behavior to make informed decisions.</div>
                    </div>
                </div>
            </div>

            <div className="projectIntro">      
            
                Sublime Technologies is a pioneering company that specializes in designing and developing cutting-edge Point of Sale (POS) systems. 
                Their innovative solutions empower businesses to streamline their sales processes, enhance customer experiences, and boost revenue
            
            </div>
        

           
        </div>
    )
}

/*
    <div className="whyus_illustration">
        <img src={pos}/>
    </div>
*/