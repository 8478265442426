export default function whyus(){
    return(
        <div className="project underlined-project">
            

                <div className="projectKeysContainer">
                    <div className="projectKeysInlineHead">
                        <div className="projectKey">      
                            <div className="projectKeyTitle">
                                Improved financial planning and budgeting
                            </div>
                            <div>
                                Create accurate budgets, track expenses, and optimize financial resources to improve financial planning.
                            </div>
                        </div>
                        <div className="InlineHead">
                            <div className='projectHead'>
                                <span className="projectNumber">03</span>
                                <span className='projectHeadTitle2'>Finance and Accounting</span>
                                
                            </div>

                        </div>
                        <div className="projectKey">
                            <div className="projectKeyTitle">
                                Enhanced decision-making with accurate financial data
                            </div>
                            <div>
                                Get real-time insights into financial performance, track key performance indicators (KPIs), and make informed decisions.
                            </div>
                        </div>
                        <div className="projectKey">
                            <div className="projectKeyTitle">
                                Reduced financial risks and improved cash flow management
                            </div>
                            <div>
                                Identify and mitigate financial risks, optimize cash flow, and improve financial stability.
                            </div>
                        </div>
                        <div className="projectKey">
                            <div className="projectKeyTitle">
                                Better compliance with financial regulations
                            </div>
                            <div>
                                Ensure compliance with financial regulations, reduce errors, and improve audit trails.
                            </div>
                        </div>
                    </div>
                </div>

                <div className="projectIntro">
                    Make informed decisions with our advanced financial management tools, providing real-time insights and analytics.
                </div>



        </div>
    )
}