import '../resources/styles/global.css'
import '../resources/styles/index.css'

import Nav from '../elements/Nav/Nav'
import Footer from '../elements/Footer/footer'

import Ml from '../elements/services/ML Project/ml'
import Pos from '../elements/services/Pos/pos'
import Animation from '../elements/services/animation/animation'
import Erp from '../elements/services/Erp/erp'
import Websites from '../elements/services/Websites/web'
import Custom from '../elements/services/Finance/finance'
import Finance from '../elements/services/Custom/custom'

import '../elements/services/styles/pos.css'
import '../elements/services/styles/ml.css'
export default function Home(){
    return(
        <div >
            <Nav/>
            <div className="services_page">
                <Erp/>
                <Pos/>
                <Finance/>
                <Ml/>
                <Animation/>
                <Websites/>
                <Custom/>
            </div>
            <div>
                
            </div>
            <Footer/>
        </div>
    )
}