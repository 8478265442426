export default function whyus(){
    return(
        <div className="project underlined-project">
            
            <div className="projectKeysContainer">
                <div className="projectKeysInlineHead">
                    <div className="projectKey">      
                        <div className="projectKeyTitle">
                            Improved efficiency and productivity
                        </div>
                        <div>
                            Automate manual tasks, streamline processes, and reduce errors to increase productivity and efficiency.
                        </div>
                        
                    </div>

                    <div className="InlineHead">
                        <div className='projectHead'>
                    
                            <span className="projectNumber">01</span>
                            <span className='projectHeadTitle2'>Enterprise Resource Planning software</span>
                            
                        </div>


                        
                    </div>
                    <div className="projectKey">
                        <div className="projectKeyTitle">
                            Enhanced decision-making capabilities
                        </div>
                        <div>
                            Get real-time insights and visibility into your business operations to make informed decisions.
                        </div>
                        
                    </div>
                    <div className="projectKey">
                        <div className="projectKeyTitle">
                            Reduced costs and improved financial management
                        </div>
                        <div>
                            Optimize financial resources, reduce waste, and improve cash flow management.
                        </div>
                        
                    </div>
                </div>
            </div>
            <div className="projectIntro">
                Streamline your business processes with our robust Enterprise Resource Planning systems, tailored to optimize efficiency and productivity.
            </div>
        </div>

    )
}