
export default function whyus(){
    return(
        <div className="project underlined-project">
            
         
              

                <div className="projectKeysContainer">
                    <div className="projectKeysInlineHead">
                        <div className="projectKey">      
                            <div className="projectKeyTitle">
                                Improved decision-making with data-driven insights
                            </div>
                            <div>
                            Get real-time insights into customer behavior, market trends, and business operations to make informed decisions.
                            </div>
                        </div>
                        <div className="InlineHead">

                            <div className='projectHead'>
                                <span className="projectNumber">04</span>
                                <span className='projectHeadTitle2'>Machine learning and Artifical Intelligence</span>
                            </div>

                        </div>
                        <div className="projectKey">
                            <div className="projectKeyTitle">
                                Enhanced competitiveness with predictive analytics
                            </div>
                            <div>
                                Use predictive analytics to identify opportunities, anticipate challenges, and stay ahead of the competition.
                            </div>
                        </div>
                        <div className="projectKey">
                            <div className="projectKeyTitle">
                                Increased efficiency and productivity
                            </div>
                            <div>
                                Automate manual tasks, optimize processes, and improve productivity with ML-powered solutions.
                            </div>
                        </div>
                        <div className="projectKey">
                            <div className="projectKeyTitle">
                                Better customer experiences with personalized recommendations
                            </div>
                            <div>
                                Provide personalized recommendations, improve customer engagement, and increase customer satisfaction.
                            </div>
                        </div>
                    </div>
                </div>

                <div className="projectIntro">
                    
                    We develop machine learning software that powers predictive insights and automation.

                </div>


        </div>
    )
}